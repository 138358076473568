<template>
	<div class="page-content no-padding">
        <div class="grid">
            <div class="col-12">
                <DataTable :value="categories" :paginator="true" class="p-datatable-gridlines" :rows="15" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll" @page="pageChanged" @row-click="rowClick">
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">{{ $appState.texts.categories }}</h5>
                        </div>
                    </template>
                    <template #empty>
                        No se han encontrado {{ $appState.texts.categories }} con tus criterios de búsqueda.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column header="Nombre" field="name" style="min-width:12rem">
                        <template #body="{data}">
                            <div style="display: inline;">
                                <iconify-icon v-if="data.extra_data.icon_type === 'icon'" :icon="data.extra_data.icon ?? 'mdi:folder-text'" class="mr-2" :style="`color: #${data.extra_data.icon_color ?? '6366F1'};font-size: 1.6em;`"></iconify-icon>
                                <img v-if="data.extra_data.icon_type === 'image'" :src="getIconImageUrl(data.id)" class="mr-2" style="height: 1.6em;" alt="">
                                <span>
                                    {{data.name}}
                                    ({{data.slug}})
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Creado el" field="created_at" dataType="created_at" style="min-width:10rem">
                        <template #body="{data}">
                            {{formatDate(data.created_at)}}
                        </template>
                    </Column>
                </DataTable>
                <DataTable :value="documents" v-model:expandedRows="expandedRows" :paginator="true" class="p-datatable-gridlines" :rows="15" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll" @page="pageChanged">
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">Documentos</h5>
                        </div>
                    </template>
                    <template #empty>
                        No se han encontrado documentos con tus criterios de búsqueda.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column header="Título" field="title" style="min-width:12rem">
                        <template #body="{data}">
                            {{data.title}}
                        </template>
                    </Column>
                    <Column header="Categoría" field="category" style="min-width:12rem">
                        <template #body="{data}">
                            {{data.category.name}}
                        </template>
                    </Column>
                    <Column header="Creado el" field="created_at" dataType="created_at" style="min-width:10rem">
                        <template #body="{data}">
                            {{formatDate(data.created_at)}}
                        </template>
                    </Column>
                    <template #expansion="{data}">
                        <div style="padding-left: 6rem;">
                            <p>{{data.description}}</p>
                            <p v-if="!isPdf(data.mime_type)"><a :href="downloadUrl(data.id)" target="_blank" class="p-button"><i class="pi pi-download mr-2"></i> Descargar documento</a></p>
                            <p v-if="isPdf(data.mime_type)"><a :href="viewPdfUrl(data.id)" target="_blank" class="p-button"><iconify-icon :icon="documentService.getDocumentIcon(data.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon> Ver documento</a></p>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import ApiService from '@/service/ApiService.js';
import DocumentService from '@/service/DocumentService.js';
import SearchService from '@/service/SearchService.js';
import ErrorReportService from '@/service/ErrorReportService.js';

const compUrl = '/search';

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    data() {
        return {
            categories: null,
            documents: null,
            page: 1,
            loading: true,
            expandedRows: [],
            query: '',
            qTimeout: null,
            working: false,
        }
    },
    props: ['routeQuery'],
    documentService: null,
    searchService: null,
    errorReportService: null,
    created() {
        this.documentService = new DocumentService();
        this.searchService = new SearchService();
        this.errorReportService = new ErrorReportService();
    },
    emits: ['updateBreadcrumbs'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: 'Búsqueda', to: compUrl + '?q=' + encodeURIComponent(this.routeQuery ?? ''), replace: true});
        this.query = this.routeQuery ?? '';
        
        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchSearch();
                },
            );
        } else {
            this.fetchSearch();
        }
    },
    updated() {
        if (this.query !== this.routeQuery) {
            this.query = this.routeQuery ?? '';
            this.fetchSearch();
        }
    },
    computed: {
        searchQuery: {
            get() {
                return this.query;
            },
            set(value) {
                if (this.qTimeout) {
                    clearTimeout(this.qTimeout);
                }
                this.qTimeout = setTimeout(() => {
                    this.query = value;

                    if (this.query.length >= 3 || this.query.length === 0) {
                        this.fetchSearch();
                    }
                }, 500);
            }
        },
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        fetchSearch() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:list')) {
                if (this.query) {
                    this.loading = true;
                    this.searchService.fetchSearch(this.$appState.estate.id, this.page, this.query).then(response => {
                        this.categories = response.data.categories.data;
                        this.documents = response.data.documents.data;
                        this.categories.forEach(category => category.created_at = new Date(category.created_at));
                        this.documents.forEach(document => document.created_at = new Date(document.created_at));
                        this.loading = false;
                    }).catch(error => {
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.loading = false;
                        this.$toast.add({severity:'error', summary: 'Error', detail: 'D-02: No se ha podido obtener los documentos', life: 3000});
                    });
                }
            } else {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'D-00: Acceso no autorizado', life: 3000});
            }
        },
        pageChanged(event) {
            this.page = event.page;
            this.fetchSearch();
        },
        downloadUrl(id) {
            return ApiService.getDocumentDownloadUrl(id);
        },
        viewPdfUrl(id) {
            return ApiService.getDocumentPdfUrl(id);
        },
        isPdf(mime_type) {
            return mime_type === 'application/pdf';
        },
        rowClick(event) {
            this.$router.push(`/categories/${event.data.slug}`);
        },
        getIconImageUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/categories/${id}/icon-image?_token=${token}`;
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            align-items: start;
        }
    }

    ::v-deep(.p-datatable .p-datatable-tbody > tr.deleted-row) {
        background-color: var(--red-50);
    }

	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-true {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-false {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

    @media screen and (max-width: 960px) {
        ::v-deep(.p-toolbar) {
            flex-wrap: wrap;
            
            .p-button {
                margin-bottom: 0.25rem;
            }
        }
    }
</style>
